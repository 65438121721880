import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import get from 'lodash/get'
import { SocialIcon } from '../ds/atoms/icons'

type Props = {
  locale: string
}
export const CollaboratorGrid = ({ locale }: Props) => {
  const data = useStaticQuery(graphql`
    query AllCollaborators {
      sanityTeam {
        headline {
          en
          sv
        }
        description {
          en
          sv
        }
      }
      allSanityCollaborator(filter: {hidden: {eq: false}}) {
        nodes {
          _id
          firstname
          lastname
          avatar {
            asset {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          role {
            en
            sv
          }
          socialLinks {
            _key
            profileUrl
            type
          }
          bio {
            en
            sv
          }
        }
      }
    }
  `)
  const teamContent = get(data, 'sanityTeam')
  const collaborators = get(data, 'allSanityCollaborator.nodes')
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">{get(teamContent, ['headline', locale])}</h2>
            <p className="text-xl leading-7 text-cool-grey-500">{get(teamContent, ['description', locale])}</p>
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            {collaborators.map(collaborator => (
              <li key={collaborator._id}>
                <div className="space-y-6">
                  <Img fluid={get(collaborator, 'avatar.asset.localFile.childImageSharp.fluid')} className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" alt={`${collaborator.firstname} ${collaborator.lastname}`} />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h4>{collaborator.firstname} {collaborator.lastname}</h4>
                      <p className="text-vivid-light-blue-600">{get(collaborator, ['role', locale])}</p>
                    </div>
                    <ul className="flex justify-center space-x-5">
                      {collaborator.socialLinks.map(link => (
                        <li key={link._key}>
                          <a href={link.profileUrl} className="text-cool-grey-400 hover:text-cool-grey-500 transition ease-in-out duration-150">
                            <span className="sr-only">{link.type}</span>
                            <SocialIcon type={link.type} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}