import React from "react"
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import filter from 'lodash/filter'
import shuffle from 'lodash/shuffle'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { SocialIcon } from "../../atoms/icons"

type Props = {
  locale: 'en' | 'sv'
  currentMentorId: string
}
export const MoreMentors = ({ locale, currentMentorId }: Props) => {
  const data = useStaticQuery(graphql`
    query AllMentorsForClickaway {
      allSanityMentor(filter: {hidden: { eq: false }}) {
        edges {
          node {
            _id
            firstname
            lastname
            role {
              en
              sv
            }
            socialLinks {
              _key
              profileUrl
              type
            }
            avatar {
              asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const mentors = get(data, 'allSanityMentor.edges')
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-cool-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-3 bg-white text-lg font-medium text-cool-gray-900">Mentors</span>
            </div>
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            {mentors && mentors.length > 0 ? shuffle(filter(mentors, mentor => mentor.node._id !== currentMentorId)).map(({ node }) => {
              const name = `${node.firstname} ${node.lastname}`
              const role = get(node, ['role', locale])
              const lower = lowerCase(name)
              const sanitizedName = kebabCase(lower)
              const link = `${locale === 'sv' ? '/sv/' : '/'}mentor/${sanitizedName}`
              const image = get(node, 'avatar.asset.localFile.childImageSharp.gatsbyImageData')
              return (
                <Link to={link}>
                  <li key={get(node, '_id')}>
                    <div className="space-y-6">
                      <GatsbyImage className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" image={image} alt={`${get(node, 'firstname')} ${get(node, 'lastname')}`} />
                      <div className="space-y-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{name}</h3>
                          <p className="text-vivid-light-blue-600">{role}</p>
                        </div>
                        <ul className="flex justify-center space-x-5">
                          {node.socialLinks.map(link => {
                            return (
                              <li key={link._key}>
                                <a href={link.profileUrl} target="_blank" rel="noopener noreferrer" className="text-cool-gray-400 hover:text-cool-gray-500">
                                  <span className="sr-only">{link.type}</span>
                                  <SocialIcon type={link.type} />
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </Link>
              )
            }) : null}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MoreMentors