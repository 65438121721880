import React from 'react'
import get from 'lodash/get'
import times from 'lodash/times'
import BlockContent from '@sanity/block-content-to-react'
import { useStaticQuery, graphql } from 'gatsby'
import { DateTime } from 'luxon'
import { getColSpan, getColStart, getRowSpan, getRowStart } from '../../../helpers/class-calculator'
import { DayHeader } from './day-header'

type Props = {
  locale: string
}
export const Schedule = ({ locale = "en" }: Props) => {
  const data = useStaticQuery(graphql`
    query SanitySchedule {
      sanitySchedule {
        title {
          en
          sv
        }
        _rawDescription
        scheduleDays {
          _key
          index
          title {
            en
            sv
          }
          _rawDescription
          start
          stop
          scheduleItems {
            _key
            name
            duration
            start
            track
          }
        }
      }
    }
  `)
  const headline = get(data, ['sanitySchedule', 'title', locale])
  const description = get(data, ['sanitySchedule', '_rawDescription', locale])
  const scheduleDays = get(data, ['sanitySchedule', 'scheduleDays'])

  const serializers = {}

  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="relative mx-auto max-w-full">
        <div className="lg:text-center">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">
            {headline}
          </h3>
          <div className="mt-4 max-w-2xl text-xl leading-7 text-cool-grey-500 lg:mx-auto">
            <BlockContent blocks={description} serializers={serializers} />
          </div>
        </div>

        <ul className={`mt-10 grid gap-4 gap-x-2 md:grid-rows-16 md:grid-cols-9`}>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-1 md:row-span-1 md:col-start-1" />
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-2 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">07:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-3 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">08:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-4 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">09:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-5 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">10:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-6 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">11:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-7 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">12:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-8 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">13:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-9 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">14:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-10 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">15:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-11 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">16:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-12 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">17:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-13 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">18:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-14 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">19:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-15 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">20:00</li>
          <li className="hidden md:flex md:justify-center md:items-center md:col-span-1 md:row-start-16 md:row-span-1 md:col-start-1 md:border-solid md:border-cool-grey-700 md:border-t-2">21:00</li>
          {scheduleDays.map(day => {
            const dayName = get(day, ['title', locale])
            let colStart = 1
            switch (day.index) {
              case 1: // thursday
                colStart = 1
                break
              case 2: // friday
                colStart = 3
                break
              case 3: // saturday
                colStart = 5
                break
              case 4: // sunday
                colStart = 7
                break
            }
            return (
              <>
                <li key={dayName} className={`${getColStart(colStart + 1, 'md')} md:col-span-2 md:row-start-1 md:row-span-1 pt-6 md:pt-0 flex justify-center items-center text-lg leading-6 font-medium text-cool-grey-900`}><DayHeader>{dayName}</DayHeader></li>
                {day.scheduleItems.map((item, index) => {
                  const span = item.track === 'all' ? 2 : 1
                  const plus = item.track === 'one' ? 1 : item.track === 'two' ? 2 : 1

                  const now = DateTime.now()
                  const start = DateTime.fromISO(`${now.toISODate()}T${item.start}`)
                  const nextStart = day.scheduleItems.length === index + 1 ? '22:00' : day.scheduleItems[index + 1].start
                  const end = DateTime.fromISO(`${now.toISODate()}T${nextStart}`)
                  const diff = end.diff(start, ['hours']).hours

                  let rowStart = 1
                  switch (item.start) {
                    case '07:00':
                      rowStart = 2
                      break
                    case '08:00':
                      rowStart = 3
                      break
                    case '09:00':
                      rowStart = 4
                      break
                    case '10:00':
                      rowStart = 5
                      break
                    case '11:00':
                      rowStart = 6
                      break
                    case '12:00':
                      rowStart = 7
                      break
                    case '13:00':
                      rowStart = 8
                      break
                    case '14:00':
                      rowStart = 9
                      break
                    case '15:00':
                      rowStart = 10
                      break
                    case '16:00':
                      rowStart = 11
                      break
                    case '17:00':
                      rowStart = 12
                      break
                    case '18:00':
                      rowStart = 13
                      break
                    case '19:00':
                      rowStart = 14
                      break
                    case '20:00':
                      rowStart = 15
                      break
                    case '21:00':
                      rowStart = 16
                      break
                    case '22:00':
                      rowStart = 17
                      break
                    default:
                      break
                  }

                  return (
                    <>
                      {index === 0 && rowStart !== 2 ? (
                        <>
                          {times(rowStart > 2 ? rowStart - 2 : 1, (i) => {
                            const mod = i === 0 ? 1 : i + 1
                            return <li key={`${item._key}-${mod}-start`} className={`${getRowStart(rowStart - mod, 'md')} ${getColStart(colStart + plus, 'md')} md:col-span-2 hidden md:flex bg-cool-grey-50 rounded-lg shadow`} />
                          })}
                        </>
                      ) : null}
                      <li key={item._key} className={`${getColSpan(span, 'md')} ${getColStart(colStart + plus, 'md')} ${getRowStart(rowStart, 'md')} ${getRowSpan(item.duration, 'md')} bg-white rounded-lg shadow divide-y divide-cool-grey-200`}>
                        <div className="w-full flex items-center justify-center h-full p-6">
                          <h3 className="hidden md:block text-cool-grey-900 text-sm font-medium text-center">{item.name}</h3>
                          <div className="flex items-center gap-x-2 md:hidden">
                            <p className="text-cool-grey-500 text-sm truncate">{item.start}</p>
                            <h3 className="text-cool-grey-900 text-sm font-medium truncate">{item.name}</h3>
                          </div>
                        </div>
                      </li>
                      {day.scheduleItems.length === index + 1 && diff !== item.duration ? (
                        <>
                          {times(diff, (i) => {
                            return <li key={`${item._key}-${index * (rowStart + 1) * i}-end`} className={`${getRowStart((rowStart + 1) + i, 'md')} ${getColStart(colStart + plus, 'md')} md:col-span-2 hidden md:flex bg-cool-grey-50 rounded-lg shadow`} />
                          })}
                        </>
                      ) : null}
                    </>
                  )
                })}
              </>
            )
          })}
        </ul>
      </div>
    </div>
  )
}