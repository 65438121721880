import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'
import BlockContent from '@sanity/block-content-to-react'
import Img from "gatsby-image"
import { SocialIcon } from '../ds/atoms/icons'

type Props = {
  content: any
  locale: string
}
export const MentorSection = ({ content = {}, locale }: Props) => {
  const serializers = {}
  return (
    <div className="bg-cool-grey-900">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl leading-9 font-extrabold text-white tracking-tight sm:text-4xl">{content.headline}</h2>
            <p className="text-xl leading-7 text-cool-grey-300">{content.description}</p>
          </div>
          <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
            {content.mentors.map(mentor => {
              const _rawDescription = locale === 'sv' ? get(mentor, 'description._rawSv') : get(mentor, 'description._rawEn')
              /* const bio = locale === 'sv' ? get(mentor, 'bio.sv') : get(mentor, 'bio.en')
              const role = locale === 'sv' ? get(mentor, 'role.sv') : get(mentor, 'role.en') */
              return (
                <li key={mentor.id} className="py-10 px-6 bg-cool-grey-800 text-center rounded-lg xl:px-10 xl:text-left">
                  <div className="space-y-6 xl:space-y-10">
                    <Img fluid={mentor.avatar.asset.localFile.childImageSharp.fluid} className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" alt="" />
                    <div className="space-y-2 xl:flex xl:flex-col xl:items-center xl:justify-between">
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h4 className="text-white">{mentor.firstname}{' '}{mentor.lastname}</h4>
                        <BlockContent blocks={_rawDescription || [{}]} serializers={serializers} renderContainerOnSingleChild className="text-vivid-light-blue-400" />
                      </div>
                      <ul className="flex justify-center space-x-5">
                        {mentor.socialLinks.map(link => (
                          <li key={link.id}>
                            <a href={link.profileUrl} target="_blank" rel="noreferrer" className="text-cool-grey-400 hover:text-cool-grey-300 transition ease-in-out duration-150">
                              <SocialIcon type={link.type} />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
export const MentorSectionLight = ({ content = {}, locale }: Props) => {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">{content.headline}</h2>
            <p className="text-xl leading-7 text-cool-grey-500">{content.description}</p>
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <Link to={`/${content.primaryButtonSlug}`} className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  {content.primaryButtonText}
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              {content.mentors.map(mentor => {
                /* const _rawDescription = locale === 'sv' ? get(mentor, 'description._rawSv') : get(mentor, 'description._rawEn') */
                const bio = locale === 'sv' ? get(mentor, 'bio.sv') : get(mentor, 'bio.en')
                /* const role = locale === 'sv' ? get(mentor, 'role.sv') : get(mentor, 'role.en') */

                return (
                  <li key={mentor.id}>
                    <div className="flex items-center space-x-4 lg:space-x-6">
                      <Img fluid={mentor.avatar.asset.localFile.childImageSharp.fluid} className="w-16 h-16 rounded-full lg:w-20 lg:h-20" alt={`${mentor.firstname} ${mentor.lastname}`} />
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h4>{mentor.firstname}{' '}{mentor.lastname}</h4>
                        <p className="text-vivid-light-blue-400">
                          {bio}
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export const MentorSectionComplex = ({ content = {}, locale }: Props) => {
  const serializers = {
    types: {
      block(props) {
        switch (props.node.style) {
          case 'normal':
            return <p className="pb-4">{props.children}</p>
          default:
            return <p className="pb-4">{props.children}</p>
        }
      },
    }
  }
  return (
    <div id="mentors" className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">{content.headline}</h2>
            <BlockContent blocks={content._rawDescription} serializers={serializers} renderContainerOnSingleChild className="text-xl leading-7 text-cool-grey-500" />
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <Link to={`/${content.primaryButtonSlug}`} className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  {content.primaryButtonText}
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              {content.mentors.map(mentor => {
                const bio = locale === 'sv' ? get(mentor, 'bio.sv') : get(mentor, 'bio.en')
                const role = locale === 'sv' ? get(mentor, 'role.sv') : get(mentor, 'role.en')

                const name = `${get(mentor, 'firstname')} ${get(mentor, 'lastname')}`
                const lower = lowerCase(name)
                const sanitizedName = kebabCase(lower)
                const link = `mentor/${sanitizedName}`

                return (
                  <li key={mentor.id}>
                    <Link to={link}>
                      <div className="space-y-4">
                        <div className="relative pb-2">
                          <Img fluid={mentor.avatar.asset.localFile.childImageSharp.fluid} className="absolute object-cover h-full w-full shadow-lg rounded-lg" alt={`${mentor.firstname} ${mentor.lastname}`} />
                        </div>
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h4>{mentor.firstname}{' '}{mentor.lastname}</h4>
                          <p className="text-vivid-light-blue-600">{role}</p>
                        </div>
                        <div className="text-lg leading-7">
                          <p className="text-cool-grey-500">{bio}</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>

  )
}