import React from 'react'
import get from 'lodash/get'
import BlockContent from '@sanity/block-content-to-react'
import { useStaticQuery, graphql } from 'gatsby'
import { Hexagon } from '../ds'

type Props = {
  locale: string
}
export const AboutSection = ({ locale }: Props) => {
  const data = useStaticQuery(graphql`
    query About {
      sanityAbout {
        headline {
          en
          sv
        }
        subline {
          en
          sv
        }
        bio {
          en
          sv
        }
        description {
          _rawSv
          _rawEn
        }
        socialLinks {
          _key
          type
          profileUrl
        }
      }
    }
  `)
  const headline = get(data, ['sanityAbout', 'headline', locale])
  const subline = get(data, ['sanityAbout', 'subline', locale])
  const bio = get(data, ['sanityAbout', 'bio', locale])
  const description = locale === 'sv' ? get(data, ['sanityAbout', 'description', '_rawSv']) : get(data, ['sanityAbout', 'description', '_rawEn'])
  /* const socialLinks = get(data, ['sanityAbout', 'socialLinks']) */

  const serializers = {}

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto">
          <Hexagon className="absolute top-12 left-full transform translate-x-32" />
          <Hexagon className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" />
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto mb-6">
          <p className="text-base text-center leading-6 text-vivid-light-blue-600 font-semibold tracking-wide uppercase">{subline}</p>
          <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">{headline}</h1>
          <p className="text-xl text-cool-grey-500 leading-8">{bio}</p>
        </div>
        <div className="prose prose-lg text-cool-grey-500 mx-auto">
          <BlockContent blocks={description} serializers={serializers} />
        </div>
      </div>
    </div>

  )
}