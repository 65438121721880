export const getColSpan = (col: number, modifier?: string): string => {
  let className = ''
  switch (col) {
    case 1:
      className = modifier ? 'md:col-span-1' : 'col-span-1'
      break
    case 2:
      className = modifier ? 'md:col-span-2' : 'col-span-2'
      break
    case 3:
      className = modifier ? 'md:col-span-3' : 'col-span-3'
      break
    case 4:
      className = modifier ? 'md:col-span-4' : 'col-span-4'
      break
    case 5:
      className = modifier ? 'md:col-span-5' : 'col-span-5'
      break
    case 6:
      className = modifier ? 'md:col-span-6' : 'col-span-6'
      break
    case 7:
      className = modifier ? 'md:col-span-7' : 'col-span-7'
      break
    case 8:
      className = modifier ? 'md:col-span-8' : 'col-span-8'
      break
    case 9:
      className = modifier ? 'md:col-span-9' : 'col-span-9'
      break
    default:
      className = modifier ? 'md:col-span-1' : 'col-span-1'
  }
  return className
}