import React from 'react'

type Props = {
  className?: string
}

const Download = ({ className }: Props) => {
  return (
    <svg className={className ? className : 'h-6 w-6'} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
    </svg>
  )
}

export default Download