import React from "react"
import * as Yup from 'yup'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { navigate } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import { useToast, useSentry } from "../../hooks"

declare global {
  interface Window {
    fathom: any
  }
}

type FieldError = {
  _key: string
  key: string
  message: string
}
const getErrorTranslation = (query: string, errors: FieldError[]): string => {
  const [match] = filter(errors, (error) => error.key === query)
  return match.message
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type FormValues = {
  email: string
  application_bot_field: string
}

type Props = {
  content: any
}

export const FooterNewsletterForm = ({ content }: Props) => {
  const { sentry, Severity } = useSentry()
  const { setContent } = useToast()

  const initialValues: FormValues = {
    email: '',
    application_bot_field: '',
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, getErrorTranslation('too_short', get(content, 'emailField.errors')))
      .max(40, getErrorTranslation('too_long', get(content, 'emailField.errors')))
      .email(getErrorTranslation('invalid', get(content, 'emailField.errors')))
      .trim()
      .lowercase()
      .required(getErrorTranslation('missing', get(content, 'emailField.errors'))),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      enableReinitialize
      onSubmit={async (values, actions) => {
        try {
          await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "footer-newsletter-form", ...values })
          })
          if (typeof window !== 'undefined' && window && window.fathom) {
            window.fathom.trackGoal('RWJ3MDA5', 0)
          }
          actions.resetForm()
          actions.setSubmitting(false)
          navigate(get(content, 'postSubmitRedirectSlug'))
        } catch (error) {
          setContent({
            type: 'error',
            headline: get(content, 'toastError.headline'),
            description: `${get(content, 'toastError.description')} ${error.message}`,
          })
          sentry.captureMessage(`<FooterNewsletterForm />: ${error.message}`, Severity.Error)
          sentry.captureException(error)
          actions.resetForm()
          actions.setSubmitting(false)
        }
      }}
    >
      {(formikBag) => {
        return (
          <Form
            className="mt-4 sm:flex sm:max-w-md"
            name="footer-newsletter-form"
            data-netlify={true}
            data-netlify-honeypot="application_bot_field"
          >
            <input type="hidden" name="form-name" value="footer-newsletter-form" />
            <Field name="application_bot_field">
              {({ field }) => (
                <p className="hidden">
                  <label htmlFor="application_bot_field">Don’t fill this out if you're human: <input {...field} /></label>
                </p>
              )}
            </Field>
            <Field name={get(content, 'emailField.identifier')}>
              {({ field, meta }) => (
                <div className="grid grid-cols-1">
                  <label htmlFor="email" className="sr-only">{get(content, 'emailField.label')}</label>
                  <input
                    {...field}
                    required
                    type={get(content, 'emailField.type')}
                    placeholder={get(content, 'emailField.placeholder')}
                    className="appearance-none min-w-0 w-full bg-white border border-cool-grey-300 rounded-md shadow-sm py-2 px-4 text-base leading-6 text-cool-grey-900 placeholder-cool-grey-500 focus:outline-none focus:border-vivid-light-blue-300 focus:shadow-outline focus:placeholder-cool-grey-400 transition duration-150 ease-in-out"
                  />
                  {meta.touched && meta.error && <p className="text-vivid-red-500 text-xs italic pt-2">{meta.error}</p>}
                </div>
              )}
            </Field>
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full bg-vivid-light-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base leading-6 font-medium text-white hover:bg-vivid-light-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                disabled={formikBag.isSubmitting || !formikBag.isValid}
              >
                {get(content, 'buttonText')}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}