import React from "react"
import get from 'lodash/get'
import { Link } from 'gatsby'
import { FooterNewsletterForm } from './sections'

export const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        {/* <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link to="/om-brandbilen" className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
              Om oss
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/intresseanmalan" className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
              Intresseanmälan
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/jobba-pa-brandbilen" className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
              Jobba hos oss
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/kontakt" className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
              Kontakta oss
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link to="/styrelsen" className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
              Styrelsen
            </Link>
          </div>
        </nav> */}
        <div className="mt-8 flex justify-center">
          <a href="https://www.instagram.com/highcoastimpactfest" target="_blank" className="ml-6 text-cool-grey-400 hover:text-cool-grey-500">
            <span className="sr-only">Instagram</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
        <div className="mt-8">
          <p className="text-center text-base leading-6 text-cool-grey-400">
            &copy; {new Date().getFullYear()} Impact Fest. All rights reserved.
            </p>
        </div>
      </div>
    </footer>
  )
}

type FooterComplexProps = {
  content: any
  newsletter: boolean
  locale: string
}
export const FooterComplex = ({ content, newsletter, locale }: FooterComplexProps) => {
  return (
    <footer className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to={`${locale === 'en' ? '/' : `/${locale}`}`}>
              <img className="h-20" src={get(content, 'logo.asset.localFile.publicURL')} alt="Impact Fest" />
            </Link>
            <p className="text-cool-grey-500 text-base leading-6">
              {get(content, 'mission')}
            </p>
            <div className="flex space-x-6">
              {get(content, 'socialLinks').map(link => (
                <a key={link._key} href={link.profileUrl} target="_blank" rel="noreferrer" className="text-cool-grey-400 hover:text-cool-grey-500">
                  <span className="sr-only">{link.type}</span>
                  <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                  </svg>
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 xl:col-span-1">
            {get(content, 'links').map(link => (
              <div key={link._key}>
                <h4 className="text-sm leading-5 font-semibold text-cool-grey-400 tracking-wider uppercase">
                  {link.headline}
                </h4>
                <ul className="mt-4 space-y-4">
                  {link.links.map(innerLink => (
                    <li key={innerLink._key}>
                      <Link to={innerLink.slug ? innerLink.slug : '/'} className="text-base leading-6 text-cool-grey-500 hover:text-cool-grey-900">
                        {innerLink.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          {newsletter ? (
            <div className="mt-8 xl:mt-0">
              <h4 className="text-sm leading-5 font-semibold text-cool-grey-400 tracking-wider uppercase">
                {get(content, 'newsletter.headline')}
              </h4>
              <p className="mt-4 text-base leading-6 text-cool-grey-500">
                {get(content, 'newsletter.subline')}
              </p>
              <FooterNewsletterForm content={get(content, 'newsletter')} />
            </div>
          ) : null}
        </div>
        <div className="mt-8 border-t border-cool-grey-200 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base leading-6 text-cool-grey-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} {get(content, 'copyright')}
          </p>
        </div>
      </div>
    </footer>
  )
}