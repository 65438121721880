import React, { useEffect } from 'react'
import { motion, AnimatePresence, useCycle } from "framer-motion"

type Props = {
  strings: string[]
}
export const TextLoop = ({ strings = [] }: Props) => {
  const [item, setItems] = useCycle(...strings)

  useEffect(() => {
    const timeOut = setTimeout(() => setItems(), 2000)
    return () => clearTimeout(timeOut)
  }, [item])

  const getVariants = () => ({
    initial: {
      y: "-30%",
      opacity: 0,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    exit: {
      y: "30%",
      opacity: 0,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
  })
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={item}
        variants={getVariants()}
        initial={"initial"}
        exit={"exit"}
        animate={"animate"}
      >
        {item}
      </motion.div>
    </AnimatePresence>
  )
}