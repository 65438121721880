import React from "react"
import { Link } from 'gatsby'
import Img from "gatsby-image"
import get from 'lodash/get'
import filter from 'lodash/filter'
import BlockContent from '@sanity/block-content-to-react'
import { Formik, Form, Field } from 'formik'
import { useToast, useSentry } from "../../hooks"
import * as Yup from 'yup'

declare global {
  interface Window {
    fathom: any
  }
}

type FieldError = {
  _key: string
  key: string
  message: string
}
const getErrorTranslation = (query: string, errors: FieldError[]): string => {
  const [match] = filter(errors, (error) => error.key === query)
  return match.message
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type FormValues = {
  email: string
  how_did_you_hear_about_us: string
  'bot-field': string
}

type Props = {
  content: any
}

export const BecomeMentorContactSection = ({ content }: Props) => {
  const { sentry, Severity } = useSentry()
  const { setContent } = useToast()

  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = '' } = mark
        const href = `/${slug}`
        return <Link to={href}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { blank, href } = mark
        const classNames =
          'font-medium underline text-vivid-light-blue-700 hover:text-vivid-light-blue-600 transition ease-in-out duration-150'
        return blank ? (
          <a href={href} className={classNames} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
            <a href={href} className={classNames}>
              {children}
            </a>
          )
      },
    },
  }

  const initialValues: FormValues = {
    email: '',
    how_did_you_hear_about_us: '',
    'bot-field': '',
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, getErrorTranslation('too_short', get(content, 'email.errors')))
      .max(40, getErrorTranslation('too_long', get(content, 'email.errors')))
      .email(getErrorTranslation('invalid', get(content, 'email.errors')))
      .trim()
      .lowercase()
      .required(getErrorTranslation('missing', get(content, 'email.errors'))),
    how_did_you_hear_about_us: Yup.string()
      .min(2, getErrorTranslation('too_short', get(content, 'howDidYouHearAboutUs.errors')))
      .max(180, getErrorTranslation('too_long', get(content, 'howDidYouHearAboutUs.errors'))),
  })

  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img fluid={get(content, 'hero.asset.localFile.childImageSharp.fluid')} className="h-56 w-full object-cover lg:absolute lg:h-full" />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
              {get(content, 'headline')}
            </h2>
            <BlockContent renderContainerOnSingleChild blocks={get(content, '_rawDescription')} serializers={serializers} className="mt-4 text-lg leading-7 text-cool-grey-500 sm:mt-3" />
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              enableReinitialize
              onSubmit={async (values, actions) => {
                try {
                  await fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "become-a-mentor", ...values })
                  })
                  if (typeof window !== 'undefined' && window && window.fathom) {
                    window.fathom.trackGoal('4VPCMYKV', 0)
                  }
                  setContent({
                    type: 'success',
                    headline: get(content, 'toastSuccess.headline'),
                    description: get(content, 'toastSuccess.description'),
                  })
                  actions.resetForm()
                  actions.setSubmitting(false)
                } catch (error) {
                  setContent({
                    type: 'error',
                    headline: get(content, 'toastError.headline'),
                    description: `${get(content, 'toastError.description')} ${error.message}`,
                  })
                  sentry.captureMessage(`<BecomePartnerContactSection />: ${error.message}`, Severity.Error)
                  sentry.captureException(error)
                  actions.resetForm()
                  actions.setSubmitting(false)
                }
              }}
            >
              {(formikBag) => {
                return (
                  <Form
                    className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                    name="become-a-mentor"
                    data-netlify={true}
                  >
                    <input type="hidden" name="form-name" value="become-a-mentor" />
                    <Field name="bot-field">
                      {({ field }) => (
                        <p className="hidden">
                          <label>Don’t fill this out if you're human: <input {...field} /></label>
                        </p>
                      )}
                    </Field>
                    <Field name={get(content, 'email.identifier')}>
                      {({ field, meta }) => (
                        <div className="sm:col-span-2">
                          <label htmlFor={get(content, 'email.identifier')} className="block text-sm font-medium leading-5 text-cool-grey-700">
                            {get(content, 'email.label')}
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              {...field}
                              type={get(content, 'email.type')}
                              placeholder={get(content, 'email.placeholder')}
                              className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            />
                          </div>
                          {meta.touched && meta.error && <p className="text-vivid-red-500 text-xs italic pt-2">{meta.error}</p>}
                        </div>
                      )}
                    </Field>
                    <Field name={get(content, 'howDidYouHearAboutUs.identifier')}>
                      {({ field, meta }) => (
                        <div className="sm:col-span-2">
                          <div className="flex justify-between">
                            <label htmlFor={get(content, 'howDidYouHearAboutUs.identifier')} className="block text-sm font-medium leading-5 text-cool-grey-700">
                              {get(content, 'howDidYouHearAboutUs.label')}
                            </label>
                            <span className="text-sm leading-5 text-cool-grey-500">{get(content, 'howDidYouHearAboutUs.modifier')}</span>
                          </div>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              {...field}
                              type={get(content, 'howDidYouHearAboutUs.type')}
                              placeholder={get(content, 'howDidYouHearAboutUs.placeholder')}
                              className="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            />
                          </div>
                          {meta.touched && meta.error && <p className="text-vivid-red-500 text-xs italic pt-2">{meta.error}</p>}
                        </div>
                      )}
                    </Field>
                    <div className="text-right sm:col-span-2">
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="submit"
                          className={`w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:border-vivid-light-blue-700 focus:shadow-outline-vivid-light-blue active:bg-vivid-light-blue-700 transition duration-150 ease-in-out ${!formikBag.dirty || (formikBag.dirty && !formikBag.isValid) ? 'text-opacity-75' : 'animate-pulse'
                            }`}
                          disabled={formikBag.isSubmitting || !formikBag.isValid}
                        >
                          <div className="w-full grid grid-cols-12">
                            <div className="col-span-11">
                              {get(content, 'primaryButtonText')}
                              {formikBag.isSubmitting ? <span className="ml-1">...</span> : null}
                            </div>
                            <div className="col-span-1">
                              {!formikBag.isSubmitting && formikBag.dirty && formikBag.isValid ? (
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                              ) : null}
                              {formikBag.isSubmitting ? (
                                <svg
                                  className="animate-spin h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  />
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  />
                                </svg>
                              ) : null}
                            </div>
                          </div>
                        </button>
                      </span>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>

  )
}