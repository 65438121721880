import React from 'react'
import * as Yup from 'yup'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import { useToast, useSentry } from "../../../hooks"

declare global {
  interface Window {
    fathom: any
  }
}

type FieldError = {
  _key: string
  key: string
  message: string
}
const getErrorTranslation = (query: string, errors: FieldError[]): string => {
  const [match] = filter(errors, (error) => error.key === query)
  return match.message
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type FormValues = {
  email: string
  application_bot_field: string
}

type Props = {
  locale: string
}
export const BlogDirectoryNewsletterSignup = ({ locale = "en" }: Props) => {
  const { sentry, Severity } = useSentry()
  const { setContent } = useToast()
  const data = useStaticQuery(graphql`
    query blogDirectoryNewsletterSection {
      sanityBlogNewsletterSection {
        privacyActualLinkText {
          en
          sv
        }
        privacyInitialLinkText {
          en
          sv
        }
        privacyLinkSlug {
          en
          sv
        }
        submitButtonText {
          en
          sv
        }
        toastSuccess {
          en {
            headline
            description
          }
          sv {
            headline
            description
          }
        }
        toastError {
          en {
            headline
            description
          }
          sv {
            headline
            description
          }
        }
        emailField {
          en {
            type
            placeholder
            label
            identifier
            errors {
              _key
              key
              message
            }
          }
          sv {
            type
            placeholder
            label
            identifier
            errors {
              _key
              key
              message
            }
          }
        }
      }
    }
  `)

  const submitButtonText = get(data, ['sanityBlogNewsletterSection', 'submitButtonText', locale])
  const privacyLinkSlug = get(data, ['sanityBlogNewsletterSection', 'privacyLinkSlug', locale])
  const privacyInitialLinkText = get(data, ['sanityBlogNewsletterSection', 'privacyInitialLinkText', locale])
  const privacyActualLinkText = get(data, ['sanityBlogNewsletterSection', 'privacyActualLinkText', locale])
  const toastSuccess = get(data, ['sanityBlogNewsletterSection', 'toastSuccess', locale])
  const toastError = get(data, ['sanityBlogNewsletterSection', 'toastError', locale])
  const emailField = get(data, ['sanityBlogNewsletterSection', 'emailField', locale])

  const initialValues: FormValues = {
    email: '',
    application_bot_field: '',
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, getErrorTranslation('too_short', get(emailField, 'errors')))
      .max(40, getErrorTranslation('too_long', get(emailField, 'errors')))
      .email(getErrorTranslation('invalid', get(emailField, 'errors')))
      .trim()
      .lowercase()
      .required(getErrorTranslation('missing', get(emailField, 'errors'))),
  })

  return (
    <div className="grid grid-cols-1 justify-start lg:justify-end">
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          try {
            await fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "blog-directory-newsletter-form", ...values })
            })
            if (typeof window !== 'undefined' && window && window.fathom) {
              window.fathom.trackGoal('NGDLLGOS', 0)
            }
            setContent({
              type: 'success',
              headline: get(toastSuccess, 'headline'),
              description: get(toastSuccess, 'description'),
            })
            actions.resetForm()
            actions.setSubmitting(false)
          } catch (error) {
            setContent({
              type: 'error',
              headline: get(toastError, 'headline'),
              description: `${get(toastError, 'description')} ${error.message}`,
            })
            sentry.captureMessage(`<BlogDirectoryNewsletterSignup />: ${error.message}`, Severity.Error)
            sentry.captureException(error)
            actions.resetForm()
            actions.setSubmitting(false)
          }
        }}
      >
        {(formikBag) => {
          return (
            <Form
              className="mt-6 flex lg:mt-0 lg:justify-end"
              name="blog-directory-newsletter-form"
              data-netlify={true}
              data-netlify-honeypot="application_bot_field"
            >
              <input type="hidden" name="form-name" value="blog-directory-newsletter-form" />
              <Field name="application_bot_field">
                {({ field }) => (
                  <p className="hidden">
                    <label htmlFor="application_bot_field">Don’t fill this out if you're human: <input {...field} /></label>
                  </p>
                )}
              </Field>
              <Field name={get(emailField, 'identifier')}>
                {({ field, meta }) => (
                  <div className="grid grid-cols-1">
                    <label htmlFor="email" className="sr-only">{get(emailField, 'label')}</label>
                    <input
                      {...field}
                      required
                      id={field.name}
                      type={get(emailField, 'type')}
                      placeholder={get(emailField, 'placeholder')}
                      className="appearance-none w-full px-4 py-2 border border-cool-grey-300 text-base leading-6 rounded-md text-cool-grey-900 bg-white placeholder-cool-grey-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out lg:max-w-xs"
                    />
                    {meta.touched && meta.error && <p className="text-vivid-red-500 text-xs italic pt-2">{meta.error}</p>}
                  </div>
                )}
              </Field>
              <span className="ml-3 flex-shrink-0 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:border-vivid-light-blue-700 focus:shadow-outline-vivid-light-blue active:bg-vivid-light-blue-700 transition ease-in-out duration-150"
                  disabled={formikBag.isSubmitting || !formikBag.isValid}
                >
                  {submitButtonText}
                </button>
              </span>
            </Form>
          )
        }}
      </Formik>
      <p className="mt-3 text-sm leading-5 text-cool-grey-300 justify-self-start lg:justify-self-end">
        {privacyInitialLinkText}{" "}
        <Link to={privacyLinkSlug} className="text-vivid-light-blue-600 font-medium underline">
          {privacyActualLinkText}
        </Link>
      </p>
    </div>
  )
}