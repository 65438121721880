export const getColStart = (col: number, modifier?: string): string => {
  let className = ''
  switch (col) {
    case 1:
      className = modifier ? 'md:col-start-1' : 'col-start-1'
      break
    case 2:
      className = modifier ? 'md:col-start-2' : 'col-start-2'
      break
    case 3:
      className = modifier ? 'md:col-start-3' : 'col-start-3'
      break
    case 4:
      className = modifier ? 'md:col-start-4' : 'col-start-4'
      break
    case 5:
      className = modifier ? 'md:col-start-5' : 'col-start-5'
      break
    case 6:
      className = modifier ? 'md:col-start-6' : 'col-start-6'
      break
    case 7:
      className = modifier ? 'md:col-start-7' : 'col-start-7'
      break
    case 8:
      className = modifier ? 'md:col-start-8' : 'col-start-8'
      break
    case 9:
      className = modifier ? 'md:col-start-9' : 'col-start-9'
      break
    default:
      className = modifier ? 'md:col-start-1' : 'col-start-1'
  }
  return className
}