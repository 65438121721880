import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'

type Props = {
  content: {
    headline: string
    description: string
  }
  locale: string
}
export const MentorGrid = ({ content, locale }: Props) => {
  const data = useStaticQuery(graphql`
    query AllMentors {
      allSanityMentor(filter: {hidden: { eq: false }}) {
        edges {
          node {
            _id
            firstname
            lastname
            avatar {
              asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1024
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const mentors = get(data, 'allSanityMentor.edges')
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">{content.headline}</h2>
            <p className="text-xl leading-7 text-cool-grey-500">{content.description}</p>
          </div>
          <ul className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
            {mentors && mentors.length > 0 ? mentors.map(({ node }) => {
              const name = `${node.firstname} ${node.lastname}`
              const lower = lowerCase(name)
              const sanitizedName = kebabCase(lower)
              const link = `${locale === 'sv' ? '/sv/' : '/'}mentor/${sanitizedName}`
              const image = get(node, 'avatar.asset.localFile.childImageSharp.gatsbyImageData')
              return (
                <li key={get(node, '_id')}>
                  <Link to={link}>
                    <div className="space-y-4">
                      <GatsbyImage className="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24" image={image} alt={`${get(node, 'firstname')} ${get(node, 'lastname')}`} />
                      <div className="space-y-2">
                        <div className="text-xs leading-4 font-medium lg:text-sm lg:leading-5">
                          <h4>{name}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              )
            }) : null}
          </ul>
        </div>
      </div>
    </div>

  )
}