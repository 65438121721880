import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'

type Props = {
  content: {
    headline: string
  }
  locale: string
}
export const SimplePartnerSection = ({ content, locale }: Props) => {
  const data = useStaticQuery(graphql`
    query AllPartners {
      allSanityPartner(filter: {hidden: { eq: false }}) {
        nodes {
          _id
          name
          logo {
            asset {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const partners = get(data, 'allSanityPartner.nodes')
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base leading-6 font-semibold uppercase text-cool-grey-600 tracking-wider">
          {content.headline}
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          {partners && partners.length > 0 ? partners.map((partner) => {
            const lower = lowerCase(partner.name)
            const sanitizedName = kebabCase(lower)
            const link = `${locale === 'sv' ? '/sv/' : '/'}partner/${sanitizedName}`
            return (
              <Link to={link} key={get(partner, '_id')} className="col-span-1 flex justify-center py-8 px-8 bg-vivid-light-blue-700">
                <img className="max-h-12" src={get(partner, 'logo.asset.localFile.publicURL')} alt={get(partner, 'name')} />
              </Link>
            )
          }) : null}
        </div>
      </div>
    </div>
  )
}