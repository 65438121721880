import React from 'react'
import { Link } from 'gatsby'
import lowerCase from 'lodash/lowerCase'
import kebabCase from 'lodash/kebabCase'

type Props = {
  content: any
  locale: string
}
export const PartnerSection = ({ content = {}, locale }: Props) => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl leading-9 font-extrabold text-cool-grey-900 sm:text-4xl sm:leading-10">
              {content.headline}
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-7 text-cool-grey-500">
              {content.description}
            </p>
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <Link to={`/${content.primaryButtonSlug}`} className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  {content.primaryButtonText}
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            {content.partners.map(partner => {
              const lower = lowerCase(partner.name)
              const sanitizedName = kebabCase(lower)
              const link = `${locale === 'sv' ? '/sv/' : '/'}partner/${sanitizedName}`
              return (
                <Link to={link} key={partner.id} className="col-span-1 flex justify-center py-8 px-8 bg-vivid-light-blue-700">
                  <img src={partner.logo.asset.localFile.publicURL} className="max-h-12" alt={partner.name} />
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}