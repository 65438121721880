import React from 'react'
import get from 'lodash/get'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import { toPlainText } from "../../../utils"
import { BlogDirectoryNewsletterSignup } from "./blog-directory-newsletter-signup"

type Props = {
  locale: string
}
export const AllBlogPosts = ({ locale = "en" }: Props) => {
  const data = useStaticQuery(graphql`
    query AllBlogPosts {
      sanityAllBlogPosts {
        headline {
          en
          sv
        }
        description {
          en
          sv
        }
        readFullStory {
          en
          sv
        }
      }
      allSanityPost(sort: {order: DESC, fields: publishedAt}) {
        nodes {
          _id
          title
          publishedAt
          slug {
            current
          }
          categories {
            _id
            title
          }
          author {
            firstname
            lastname
            avatar {
              asset {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 256) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          _rawExcerpt
        }
      }
    }
  `)
  const posts = get(data, ['allSanityPost', 'nodes'])
  const headline = get(data, ['sanityAllBlogPosts', 'headline', locale])
  const description = get(data, ['sanityAllBlogPosts', 'description', locale])
  const readFullStory = get(data, ['sanityAllBlogPosts', 'readFullStory', locale])

  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-cool-grey-900 sm:text-4xl sm:leading-10">
            {headline}
          </h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-start">
            <p className="text-xl leading-7 text-cool-grey-500 align-top">
              {description}
            </p>
            <BlogDirectoryNewsletterSignup locale={locale} />
          </div>
        </div>
        <div className="mt-6 grid gap-16 border-t-2 border-cool-grey-100 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {posts.map(post => {
            const publishedAt = DateTime.fromISO(get(post, 'publishedAt')).setLocale(locale).toLocaleString(DateTime.DATE_MED)
            const publishedIso = DateTime.fromISO(get(post, 'publishedAt')).toISODate()
            return (
              <div key={get(post, '_id')}>
                <p className="text-sm leading-5 text-cool-grey-500">
                  <time dateTime={publishedIso}>{publishedAt}</time>
                </p>
                <Link to={'/post/' + get(post, 'slug.current')} className="block">
                  <h3 className="mt-2 text-xl leading-7 font-semibold text-cool-grey-900">
                    {get(post, 'title')}
                  </h3>
                  <p className="mt-3 text-base leading-6 text-cool-grey-500">
                    {toPlainText(get(post, '_rawExcerpt'))}
                  </p>
                </Link>
                <div className="mt-3">
                  <Link to={'/post/' + get(post, 'slug.current')} className="text-base leading-6 font-semibold text-vivid-light-blue-600 hover:text-vivid-light-blue-500 transition ease-in-out duration-150">
                    {readFullStory}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}