export const getRowSpan = (row: number, modifier?: string): string => {
  let className = ''
  switch (row) {
    case 1:
      className = modifier ? 'md:row-span-1' : 'row-span-1'
      break
    case 2:
      className = modifier ? 'md:row-span-2' : 'row-span-2'
      break
    case 3:
      className = modifier ? 'md:row-span-3' : 'row-span-3'
      break
    case 4:
      className = modifier ? 'md:row-span-4' : 'row-span-4'
      break
    case 5:
      className = modifier ? 'md:row-span-5' : 'row-span-5'
      break
    case 6:
      className = modifier ? 'md:row-span-6' : 'row-span-6'
      break
    case 7:
      className = modifier ? 'md:row-span-7' : 'row-span-7'
      break
    case 8:
      className = modifier ? 'md:row-span-8' : 'row-span-8'
      break
    case 9:
      className = modifier ? 'md:row-span-9' : 'row-span-9'
      break
    case 10:
      className = modifier ? 'md:row-span-10' : 'row-span-10'
      break
    case 11:
      className = modifier ? 'md:row-span-11' : 'row-span-11'
      break
    case 12:
      className = modifier ? 'md:row-span-12' : 'row-span-12'
      break
    case 13:
      className = modifier ? 'md:row-span-13' : 'row-span-13'
      break
    case 14:
      className = modifier ? 'md:row-span-14' : 'row-span-14'
      break
    case 15:
      className = modifier ? 'md:row-span-15' : 'row-span-15'
      break
    case 16:
      className = modifier ? 'md:row-span-16' : 'row-span-16'
      break
    case 17:
      className = modifier ? 'md:row-span-17' : 'row-span-17'
      break
    default:
      className = modifier ? 'md:row-span-1' : 'row-span-1'
  }
  return className
}