import bolt from './bolt'
import fire from './fire'
import download from './download'
import group from './group'
import social from './social'
export * from './social'

export const icons = {
  bolt,
  fire,
  download,
  group,
  social,
}

export default icons