import React, { FC } from "react"
/* import { useStaticQuery, graphql } from "gatsby" */
import { Header } from "./header"
import { FooterComplex } from "./footer"
import { AnnouncementBanner, Toast } from "./ds"
import "./layout.css"

type Props = {
  header?: boolean
  banner?: boolean
  footer?: boolean
  footerContent?: any
  newsletter?: boolean
  locale?: string
}

export const Layout: FC<Props> = ({ children, header, banner, footer, footerContent = {}, newsletter = true, locale = 'en' }) => {
  return (
    <div className="relative bg-white overflow-hidden">
      {banner ? <AnnouncementBanner /> : null}
      {header ? <Header /> : null}
      <div className="relative">
        <main>
          <Toast />
          {children}
        </main>
      </div>
      {footer ? <FooterComplex content={footerContent} newsletter={newsletter} locale={locale} /> : null}
    </div>
  )
}

export default Layout
