export const getRowStart = (row: number, modifier?: string): string => {
  let className = ''
  switch (row) {
    case 1:
      className = modifier ? 'md:row-start-1' : 'row-start-1'
      break
    case 2:
      className = modifier ? 'md:row-start-2' : 'row-start-2'
      break
    case 3:
      className = modifier ? 'md:row-start-3' : 'row-start-3'
      break
    case 4:
      className = modifier ? 'md:row-start-4' : 'row-start-4'
      break
    case 5:
      className = modifier ? 'md:row-start-5' : 'row-start-5'
      break
    case 6:
      className = modifier ? 'md:row-start-6' : 'row-start-6'
      break
    case 7:
      className = modifier ? 'md:row-start-7' : 'row-start-7'
      break
    case 8:
      className = modifier ? 'md:row-start-8' : 'row-start-8'
      break
    case 9:
      className = modifier ? 'md:row-start-9' : 'row-start-9'
      break
    case 10:
      className = modifier ? 'md:row-start-10' : 'row-start-10'
      break
    case 11:
      className = modifier ? 'md:row-start-11' : 'row-start-11'
      break
    case 12:
      className = modifier ? 'md:row-start-12' : 'row-start-12'
      break
    case 13:
      className = modifier ? 'md:row-start-13' : 'row-start-13'
      break
    case 14:
      className = modifier ? 'md:row-start-14' : 'row-start-14'
      break
    case 15:
      className = modifier ? 'md:row-start-15' : 'row-start-15'
      break
    case 16:
      className = modifier ? 'md:row-start-16' : 'row-start-16'
      break
    case 17:
      className = modifier ? 'md:row-start-17' : 'row-start-17'
      break
    default:
      className = modifier ? 'md:row-start-1' : 'row-start-1'
  }
  return className
}