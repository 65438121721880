import React from "react"
import Img from "gatsby-image"
import BlockContent from '@sanity/block-content-to-react'
import { useStaticQuery, graphql } from "gatsby"

type Props = {
  content?: any
}
export const Manifest = ({ content }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      mountain: file(relativePath: { eq: "content/hogakusten08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const serializers = {}

  return (
    <div className="relative bg-white" id="manifest">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <Img fluid={data.mountain.childImageSharp.fluid} className="h-56 w-full object-cover lg:absolute lg:h-full" />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <p className="leading-6 text-vivid-light-blue-600 font-semibold tracking-wide uppercase">{content.subline}</p>
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">{content.headline}</h1>
            <p className="text-lg leading-7 text-cool-grey-500 mb-5">{content.initial}</p>
            <div className="prose text-cool-grey-500">
              <BlockContent blocks={content._rawText} serializers={serializers} />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}