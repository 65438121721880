import React, { FC } from 'react'

export const DayHeader: FC<{ className?: string }> = ({ children, className = "" }) => (
  <h3 className={`text-2xl tracking-tight font-extrabold text-white sm:text-3xl md:text-4xl ${className}`}>
    <span className="inline-flex relative text-white">
      <span
        aria-hidden={false}
        className="inline absolute inset-0 bg-vivid-light-blue-500 transform scale-105 rounded-lg"></span>
      <span className="relative p-2">{children}</span>
    </span>
  </h3>
)