/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { BrowserClient, Severity, Event, EventHint, Scope } from '@sentry/browser'

declare global {
  interface Window {
    Sentry: any
  }
}

export const useSentry = () => {
  const defaultSentry = {
    captureEvent: (event: Event, hint?: EventHint, scope?: Scope) => '',
    captureException: (exception: unknown, hint?: EventHint, scope?: Scope) => '',
    captureMessage: (message: string, level?: Severity, hint?: EventHint, scope?: Scope) => '',
  } as BrowserClient
  const [sentry, setSentryInstance] = useState<BrowserClient>(defaultSentry)

  async function init() {
    if (typeof window !== 'undefined' && window.Sentry) {
      setSentryInstance(window.Sentry)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return { sentry, Severity }
}

export default useSentry
