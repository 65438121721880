import React from 'react'
import get from 'lodash/get'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import Img from "gatsby-image"
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import { toPlainText } from "../../../utils"

type Props = {
  locale: string
}
export const RecentBlogPosts = ({ locale = "en" }: Props) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query RecentBlogPosts {
      sanityRecentBlogPosts {
        headline {
          en
          sv
        }
        description {
          en
          sv
        }
      }
      allSanityPost(sort: {order: DESC, fields: publishedAt}, limit: 3) {
        nodes {
          _id
          title
          publishedAt
          slug {
            current
          }
          categories {
            _id
            title
          }
          author {
            firstname
            lastname
            avatar {
              asset {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 256) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          _rawExcerpt
        }
      }
    }
  `)
  const posts = get(data, ['allSanityPost', 'nodes'])
  const headline = get(data, ['sanityRecentBlogPosts', 'headline', locale])
  const description = get(data, ['sanityRecentBlogPosts', 'description', locale])

  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-cool-grey-900 sm:text-4xl sm:leading-10">
            {headline}
          </h2>
          <p className="mt-3 text-xl leading-7 text-cool-grey-500 sm:mt-4">
            {description}
          </p>
        </div>
        <div className="mt-12 grid gap-16 border-t-2 border-cool-grey-100 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {filter(posts, post => !includes(pathname, get(post, 'slug.current'))).map(post => {
            const publishedAt = DateTime.fromISO(get(post, 'publishedAt')).setLocale(locale).toLocaleString(DateTime.DATE_MED)
            const publishedIso = DateTime.fromISO(get(post, 'publishedAt')).toISODate()
            return (
              <div key={get(post, '_id')}>
                <div>
                  <Link to={'/post/' + get(post, 'slug.current')} className="inline-block">
                    {get(post, 'categories').map(category => (
                      <span key={get(category, '_id')} className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-vivid-light-blue-100 text-vivid-light-blue-800">
                        {get(category, 'title')}
                      </span>
                    ))}
                  </Link>
                </div>
                <Link to={'/post/' + get(post, 'slug.current')} className="block">
                  <h3 className="mt-4 text-xl leading-7 font-semibold text-cool-grey-900">
                    {get(post, 'title')}
                  </h3>
                  <p className="mt-3 text-base leading-6 text-cool-grey-500">
                    {toPlainText(get(post, '_rawExcerpt'))}
                  </p>
                </Link>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <Link to={'/post/' + get(post, 'slug.current')}>
                      <Img fluid={get(post, 'author.avatar.asset.localFile.childImageSharp.fluid')} className="h-10 w-10 rounded-full" />
                    </Link>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-cool-grey-900">
                      <Link to={'/post/' + get(post, 'slug.current')}>
                        {get(post, 'author.firstname')} {get(post, 'author.lastname')}
                      </Link>
                    </p>
                    <div className="flex text-sm leading-5 text-cool-grey-500">
                      <time dateTime={publishedIso}>
                        {publishedAt}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}