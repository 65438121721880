import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { TextLoop } from '../ds'

type Props = {
  content: any
}

export const HeroCtaSection = ({ content }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      forest: file(relativePath: { eq: "content/hogakusten05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <main className="lg:relative">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-cool-grey-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
            {content.headlinePlainPartOne}{' '}
            <span className="text-vivid-light-blue-600"><TextLoop strings={content.headlineRollingString} /></span>{' '}
            {content.headlinePlainPartTwo}
          </h2>
          <p className="mt-3 max-w-md mx-auto text-lg text-cool-grey-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {content.description}
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a href="#newsletter" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-600 hover:bg-vivid-light-blue-500 focus:outline-none focus:border-vivid-light-blue-700 focus:shadow-outline-vivid-light-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                {content.primaryCtaButtonText}
              </a>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a href="#mentors" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-vivid-light-blue-600 bg-white hover:text-vivid-light-blue-500 focus:outline-none focus:border-vivid-light-blue-300 focus:shadow-outline-vivid-light-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                {content.secondaryCtaButtonText}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <Img fluid={data.forest.childImageSharp.fluid} className="absolute inset-0 w-full h-full object-cover" />
      </div>
    </main>
  )
}