import React from 'react'
import * as Yup from 'yup'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import { useToast, useSentry } from "../../../hooks"

declare global {
  interface Window {
    fathom: any
  }
}

type FieldError = {
  _key: string
  key: string
  message: string
}
const getErrorTranslation = (query: string, errors: FieldError[]): string => {
  const [match] = filter(errors, (error) => error.key === query)
  return match.message
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type FormValues = {
  email: string
  application_bot_field: string
}

type Props = {
  locale: string
}
export const BlogPostNewsletterSignup = ({ locale = "en" }: Props) => {
  const { sentry, Severity } = useSentry()
  const { setContent } = useToast()
  const data = useStaticQuery(graphql`
    query blogNewsletterSection {
      sanityBlogNewsletterSection {
        headline {
          en
          sv
        }
        description {
          en
          sv
        }
        privacyActualLinkText {
          en
          sv
        }
        privacyInitialLinkText {
          en
          sv
        }
        privacyLinkSlug {
          en
          sv
        }
        submitButtonText {
          en
          sv
        }
        toastSuccess {
          en {
            headline
            description
          }
          sv {
            headline
            description
          }
        }
        toastError {
          en {
            headline
            description
          }
          sv {
            headline
            description
          }
        }
        emailField {
          en {
            type
            placeholder
            label
            identifier
            errors {
              _key
              key
              message
            }
          }
          sv {
            type
            placeholder
            label
            identifier
            errors {
              _key
              key
              message
            }
          }
        }
      }
    }
  `)

  const headline = get(data, ['sanityBlogNewsletterSection', 'headline', locale])
  const description = get(data, ['sanityBlogNewsletterSection', 'description', locale])
  const submitButtonText = get(data, ['sanityBlogNewsletterSection', 'submitButtonText', locale])
  const privacyLinkSlug = get(data, ['sanityBlogNewsletterSection', 'privacyLinkSlug', locale])
  const privacyInitialLinkText = get(data, ['sanityBlogNewsletterSection', 'privacyInitialLinkText', locale])
  const privacyActualLinkText = get(data, ['sanityBlogNewsletterSection', 'privacyActualLinkText', locale])
  const toastSuccess = get(data, ['sanityBlogNewsletterSection', 'toastSuccess', locale])
  const toastError = get(data, ['sanityBlogNewsletterSection', 'toastError', locale])
  const emailField = get(data, ['sanityBlogNewsletterSection', 'emailField', locale])

  const initialValues: FormValues = {
    email: '',
    application_bot_field: '',
  }

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, getErrorTranslation('too_short', get(emailField, 'errors')))
      .max(40, getErrorTranslation('too_long', get(emailField, 'errors')))
      .email(getErrorTranslation('invalid', get(emailField, 'errors')))
      .trim()
      .lowercase()
      .required(getErrorTranslation('missing', get(emailField, 'errors'))),
  })

  return (
    <div className="bg-cool-grey-800">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-1">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10" id="newsletter-headline">
            {headline}
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-cool-grey-300">
            {description}
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            enableReinitialize
            onSubmit={async (values, actions) => {
              try {
                await fetch("/", {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({ "form-name": "blog-post-newsletter-form", ...values })
                })
                if (typeof window !== 'undefined' && window && window.fathom) {
                  window.fathom.trackGoal('H8FLNCMS', 0)
                }
                setContent({
                  type: 'success',
                  headline: get(toastSuccess, 'headline'),
                  description: get(toastSuccess, 'description'),
                })
                actions.resetForm()
                actions.setSubmitting(false)
              } catch (error) {
                setContent({
                  type: 'error',
                  headline: get(toastError, 'headline'),
                  description: `${get(toastError, 'description')} ${error.message}`,
                })
                sentry.captureMessage(`<BlogPostNewsletterSignup />: ${error.message}`, Severity.Error)
                sentry.captureException(error)
                actions.resetForm()
                actions.setSubmitting(false)
              }
            }}
          >
            {(formikBag) => {
              return (
                <Form
                  className="mt-4 sm:flex sm:max-w-md"
                  name="blog-post-newsletter-form"
                  data-netlify={true}
                  data-netlify-honeypot="application_bot_field"
                >
                  <input type="hidden" name="form-name" value="blog-post-newsletter-form" />
                  <Field name="application_bot_field">
                    {({ field }) => (
                      <p className="hidden">
                        <label htmlFor="application_bot_field">Don’t fill this out if you're human: <input {...field} /></label>
                      </p>
                    )}
                  </Field>
                  <Field name={get(emailField, 'identifier')}>
                    {({ field, meta }) => (
                      <div className="grid grid-cols-1">
                        <label htmlFor="email" className="sr-only">{get(emailField, 'label')}</label>
                        <input
                          {...field}
                          required
                          id={field.name}
                          type={get(emailField, 'type')}
                          placeholder={get(emailField, 'placeholder')}
                          className="appearance-none min-w-0 w-full bg-white border border-cool-grey-300 rounded-md shadow-sm py-2 px-4 text-base leading-6 text-cool-grey-900 placeholder-cool-grey-500 focus:outline-none focus:border-vivid-light-blue-300 focus:shadow-outline focus:placeholder-cool-grey-400 transition duration-150 ease-in-out"
                        />
                        {meta.touched && meta.error && <p className="text-vivid-red-500 text-xs italic pt-2">{meta.error}</p>}
                      </div>
                    )}
                  </Field>
                  <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="w-full bg-vivid-light-blue-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base leading-6 font-medium text-white hover:bg-vivid-light-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      disabled={formikBag.isSubmitting || !formikBag.isValid}
                    >
                      {submitButtonText}
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
          <p className="mt-3 text-sm leading-5 text-cool-grey-300">
            {privacyInitialLinkText}{" "}
            <Link to={privacyLinkSlug} className="text-white font-medium underline">
              {privacyActualLinkText}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}