import React from "react"
import { Link } from 'gatsby'
import logo from '../images/logo/logo.svg'

export const Header = () => {
  return (
    <header>
      <div className="relative bg-cool-grey-50">
        <div className="relative bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="lg:w-0 lg:flex-1">
                <Link to="/" className="flex">
                  <img className="h-10 w-auto sm:h-12" src={logo} alt="Impact Fest" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
