import React from "react"
import { Link } from 'gatsby'

declare global {
  interface Window {
    fathom: any
  }
}

type Props = {
  content: any
}
export const FinalCta = ({ content }: Props) => {
  return (
    <div className="bg-white" id="newsletter">
      <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="px-6 py-6 bg-vivid-light-blue-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
              {content.headline}
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-vivid-light-blue-200" id="newsletter-headline">
              {content.description}
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <form
              aria-labelledby="newsletter-headline"
              className="sm:flex"
              name="newsletter-form"
              method="POST"
              data-netlify={true}
              data-netlify-honeypot="application_bot_field"
              action={content.postSubmitRedirect}
              onSubmit={() => window.fathom.trackGoal('Y040ECYV', 0)}
            >
              <input type="hidden" name="form-name" value="newsletter-form" />
              <div className="hidden">
                <label htmlFor="application_bot_field">Don’t fill this out if you're human</label>
                <input id="application_bot_field" name="application_bot_field" />
              </div>
              <input aria-label="Email address" type="email" required name="email" id="email" className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-cool-grey-900 bg-white placeholder-cool-grey-500 focus:outline-none focus:placeholder-cool-grey-400 transition duration-150 ease-in-out" placeholder={content.placeholder} />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-vivid-light-blue-500 hover:bg-vivid-light-blue-400 focus:outline-none focus:bg-vivid-light-blue-400 transition duration-150 ease-in-out">
                  {content.submitButtonText}
                </button>
              </div>
            </form>
            <p className="mt-3 text-sm leading-5 text-vivid-light-blue-200">
              {content.PrivacyInitialLinkText}{" "}
              <Link to={content.PrivacyLinkSlug} className="text-white font-medium underline">
                {content.PrivacyActualLinkText}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}